import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Text } from 'components';
import Verified from 'assets/icons/verified-icon.svg';
import { decideWeightDisplayValue } from 'utils/units';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface ReviewItem {
  review: string;
  name: string;
  img: string;
  kgConvert: number;
}

interface ReviewsTypes {
  buttonTitle: string;
  list: ReviewItem[];
  onContinue: () => void;
  verified?: string;
}

const ResultsReviews: FC<ReviewsTypes> = ({
  buttonTitle,
  list,
  onContinue,
  verified,
}) => {
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  console.log(verified);
  return (
    <Container>
      <ListContainer>
        {list.map((item, index) => {
          const weight = decideWeightDisplayValue(
            item?.kgConvert,
            quiz_answers?.units_of_measure,
          );
          const weight2 = decideWeightDisplayValue(
            item?.kgConvert2,
            quiz_answers?.units_of_measure,
          );
          return (
            <ListItem key={index}>
              <ImgContainer>
                <StyledImg src={item.img} alt="Nord Pilates users" />
              </ImgContainer>
              <TextContainer>
                <ListText>{item.review.replace('${kg}', weight)}</ListText>
                <BottomContainer>
                  <Name>{item.name}</Name>
                  <VerifiedMain>
                    <Verified />
                    <VerifiedText>
                      {verified ? verified : 'Verified'}
                    </VerifiedText>
                  </VerifiedMain>
                </BottomContainer>
              </TextContainer>
            </ListItem>
          );
        })}
      </ListContainer>
      <ButtonContainer>
        <ContinueButtonStyled
          onClick={nextStep}
          loading={loading}
          disabled={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsReviews;

const VerifiedMain = styled.section`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;

const VerifiedText = styled.p`
  color: #00b57a;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
`;

const Container = styled.div`
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
  }
`;

const TextContainer = styled.section`
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
    padding-bottom: 1rem;
  }
`;

const ListItem = styled.section`
  display: flex;
  gap: 1rem;
  overflow: hidden;
  border-radius: 0.625rem;
  border: 1px solid #ebebeb;
  background: #f7f7f7;
`;

const ListText = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
`;

const Name = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 6.125rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 6.125rem;
  height: 100%;
`;
