import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import { tablet } from 'styles/breakpoints';
import { LoadingPage, PrimaryButton, Seo, Text } from 'components';
import { useRouter } from 'apis/history';
import { useLocation } from '@reach/router';
import FatBurning from './components/FatBurning';
import ResultsPersonalized from './components/ResultsPersonalized';
import ResultsProgress from 'components/ResultsProgress';
import Header from 'components/Header';
import Summary from './components/Summary';
import ResultsReviews from './components/ResultsReviews';
import ResultsPlanInfo from './components/ResultsPlanInfo';

enum SlideType {
  ResultsSummary = 'resultsSummary',
  ResultsFatBurning = 'fatBurning',
  ResultsPersonalized = 'resultsPersonalized',
  ResultsPlanInfo = 'resultsPlanInfo',
  ResultsReviews = 'resultsReviews',
}

const ResultsSlides = () => {
  const user = useSelector((state: AppState) => state.user);
  const data = useQuizData('resultsSlides');
  const slides = data?.sections || [];
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const { goToCheckout } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sParam = params.get('s');
  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sParam = params.get('s');

    const slideIndex =
      sParam && !isNaN(Number(sParam)) ? Number(sParam) - 1 : 0;

    setCurrentSlideIndex(slideIndex);

    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location.search, slides]);

  const updateUrlParams = (slideIndex: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('s', slideIndex.toString());
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`,
    );
  };

  const handleContinueClick = () => {
    if (currentSlideIndex + 1 >= slides.length) {
      setLoading(true);
      return goToCheckout({ discount: false });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const nextSlideIndex = currentSlideIndex + 1;
    setCurrentSlideIndex(nextSlideIndex);
    updateUrlParams(nextSlideIndex + 1);
  };

  if (!slides.length || loading) {
    return <LoadingPage />;
  }

  const currentSlide = slides[currentSlideIndex];

  const renderSlideContent = () => {
    switch (currentSlide?.type) {
      case SlideType.ResultsSummary:
        return <Summary {...currentSlide} onContinue={handleContinueClick} />;
      case SlideType.ResultsFatBurning:
        return (
          <FatBurning {...currentSlide} onContinue={handleContinueClick} />
        );
      case SlideType.ResultsPersonalized:
        return (
          <ResultsPersonalized
            {...currentSlide}
            onContinue={handleContinueClick}
          />
        );
      case SlideType.ResultsReviews:
        return (
          <ResultsReviews {...currentSlide} onContinue={handleContinueClick} />
        );
      case SlideType.ResultsPlanInfo:
        return (
          <ResultsPlanInfo {...currentSlide} onContinue={handleContinueClick} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Seo />
      <Outter>
        <Header sticky={false} />
        <ResultsProgress
          step={currentSlideIndex + 1}
          totalSteps={slides.length}
        />
      </Outter>
      <Main>
        <Inner>
          <Title dangerouslySetInnerHTML={{ __html: currentSlide?.label }} />
          {currentSlide?.subtitle && (
            <Subtitle
              dangerouslySetInnerHTML={{ __html: currentSlide?.subtitle }}
            />
          )}
          <SlideWrapper>{renderSlideContent()}</SlideWrapper>
        </Inner>
      </Main>
    </>
  );
};

export default ResultsSlides;

const Main = styled.section`
  min-height: calc(100vh - 76px);

  @media ${tablet} {
    min-height: calc(100vh - 68px);
  }
`;

// const Outter = styled.section`
//   display: flex;
//   flex-direction: column;
//   position: sticky;
//   top: 0;
//   z-index: 3;
// `;

const SlideWrapper = styled.div``;

const Inner = styled.section`
  margin: 0 auto;
  max-width: 30.75rem;
  width: 100%;
  padding: 2rem 1rem 3.5rem;
  @media ${tablet} {
    padding: 1rem 1rem 4rem;
  }
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  padding-bottom: 0.5rem;
  span {
    color: #f27999;
  }
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-bottom: 1.5rem;
  span {
    font-weight: 700;
  }
`;
