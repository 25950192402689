import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { PrimaryButton, Text } from 'components';
import NowSvg from 'assets/icons/results/now.svg';
import AfterSvg from 'assets/icons/results/after.svg';
import RedTriangle from 'assets/icons/chart-icons/red-triangle.svg';
import GreenTriangle from 'assets/icons/chart-icons/green-triangle.svg';

import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  YAxis,
} from 'recharts';

interface ResultsPersonalizedTypes {
  time: string[];
  now: string;
  after: string;
  you: string;
  buttonTitle: string;
  onContinue: () => void;
}

const chartData = [
  { name: 'Page A', uv: 1500, pv: 0, amt: 6000 },
  { name: 'Page B', uv: 1520, pv: 0, amt: 4300 },
  { name: 'Page C', uv: 1200, pv: 0, amt: 3500 },
  { name: 'Page D', uv: 1500, pv: 0, amt: 2950 },
  { name: 'Page E', uv: 1200, pv: 0, amt: 1700 },
  { name: 'Page F', uv: 1500, pv: 0, amt: 1200 },
  { name: 'Page G', uv: 1200, pv: 0, amt: 1020 },
  { name: 'Page H', uv: 1500, pv: 0, amt: 900 },
  { name: 'Page F', uv: 1500, pv: 0, amt: 700 },
];

const ResultsPersonalized: FC<ResultsPersonalizedTypes> = ({
  time,
  now,
  after,
  you,
  buttonTitle,
  onContinue,
}) => {
  const { isTablet } = useQuery();
  const [loading, setLoading] = useState(false);
  const [showCurrent, setShowCurrent] = useState(false);
  const [showAfter, setShowAfter] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };

  useEffect(() => {
    const currentTimer = setTimeout(() => setShowCurrent(true), 200);
    const afterTimer = setTimeout(() => setShowAfter(true), 1300);

    return () => {
      clearTimeout(currentTimer);
      clearTimeout(afterTimer);
    };
  }, []);

  return (
    <Container>
      <Your>{you}</Your>
      <ChartContainer>
        <NowContainer visible={showCurrent}>
          <RedBubble />

          <NowText>
            <RedTriangle />
            {now}
          </NowText>
        </NowContainer>
        <AfterContainer visible={showAfter}>
          <TextInner>
            <AfterText dangerouslySetInnerHTML={{ __html: after }} />
            <GreenTriangle />
          </TextInner>
          <GreenBubble />
        </AfterContainer>

        <ResponsiveContainer width="100%" height={isTablet ? 174 : 216}>
          <ComposedChart
            width={411}
            height={isTablet ? 174 : 216}
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <YAxis
              tickCount={4} // Set the number of ticks
              axisLine={false} // Remove the axis line
              tickLine={false} // Remove tick marks
              hide
            />

            <CartesianGrid
              stroke="#f1f1f1"
              strokeDasharray="4 0"
              vertical={false}
              horizontal={true}
            />
            <defs>
              <linearGradient
                id="colorUv"
                x1="4.00913"
                y1="54.1714"
                x2="424.009"
                y2="273.457"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#ED8B76" />
                <stop offset="0.1375" stopColor="#EF9D7A" />
                <stop offset="0.275" stopColor="#F0AA7C" />
                <stop offset="0.548429" stopColor="#EEEE92" />
                <stop offset="1" stopColor="#71CE7A" />
              </linearGradient>
            </defs>

            <Area
              type="monotone"
              dataKey="amt"
              stroke="url(#colorUv)"
              fill="url(#colorUv)"
              strokeWidth={7}
              fillOpacity={0.4}
              animationDuration={1700}
              strokeOpacity={1}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
      <Times>
        {time?.map((item, index) => (
          <Time key={index}>{item}</Time>
        ))}
      </Times>

      <ButtonContainer>
        <ContinueButtonStyled
          onClick={nextStep}
          disabled={loading}
          loading={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsPersonalized;

const RedBubble = styled.section`
  background: #f8766d;
  border: 4px solid #fff;

  box-shadow: 0px 87px 27px 0px rgba(0, 0, 0, 0),
    0px 26px 24px 0px rgba(0, 0, 0, 0.01), 0px 0px 9px 0px rgba(0, 0, 0, 0.05),
    0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  @media ${tablet} {
    width: 1.125rem;
    height: 1.125rem;
  }
`;
const GreenBubble = styled.section`
  background: #3fa134;
  border: 4px solid #fff;
  margin-right: 0.19rem;
  box-shadow: 0px 87px 27px 0px rgba(0, 0, 0, 0),
    0px 26px 24px 0px rgba(0, 0, 0, 0.01), 0px 0px 9px 0px rgba(0, 0, 0, 0.05),
    0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  @media ${tablet} {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const TextInner = styled.section`
  position: relative;

  svg {
    position: absolute;
    right: 3px;
    bottom: -0.5rem;
  }
`;

const Your = styled(Text)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  padding-bottom: 2.51rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    padding: 0 1rem 1.89rem;
  }
`;

const NowText = styled.p`
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  background: #f8766d;
  min-width: 3.5rem;
  width: fit-content;
  height: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  box-shadow: 0px 41px 11px 0px rgba(0, 0, 0, 0),
    0px 26px 10px 0px rgba(0, 0, 0, 0.01), 0px 15px 9px 0px rgba(0, 0, 0, 0.05),
    0px 6px 6px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    left: -0.38rem;
  }
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    min-width: 3.3125rem;
    height: 1.875rem;
  }
`;

const NowContainer = styled.section<{ visible: boolean }>`
  position: absolute;
  top: -8%;
  left: -0.625rem;
  z-index: 4;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  @media ${tablet} {
    top: -7%;
    left: 0.563rem;
  }
`;

const AfterText = styled.p`
  position: relative;
  color: #fff;
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  span {
    display: block;
  }
  width: fit-content;
  padding: 0.3rem 0.5rem;
  border-radius: 0.26631rem;
  background: #3fa134;
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
`;

const AfterContainer = styled.section<{ visible: boolean }>`
  position: absolute;
  bottom: 1.1rem;
  right: -0.76rem;
  z-index: 4;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 0.81rem;
  @media ${tablet} {
    bottom: 0.8rem;
    right: 0.25rem;
  }
`;

const ChartContainer = styled.section`
  position: relative;
  width: 100%;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const Times = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.67056rem;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    padding: 0.5rem 1rem 0;
  }
`;

const Time = styled.span`
  color: #777;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
`;

const Container = styled.div`
  max-width: 28.75rem;
  margin: 0 auto;
  width: 100%;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #ffffff;
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #ffffff;
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
